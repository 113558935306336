import AdapterDateFns from '@mui/lab/AdapterDateFns';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { it } from 'date-fns/locale';
import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter, Route, Routes
} from "react-router-dom";
import App from './App';
import FirstStep from './FirstStep';
import './index.css';
import RegisterNewChild from './RegisterNewChild';
import { themeOptions } from './theme';


ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={createTheme(themeOptions)}>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={it}>
        <CssBaseline />
        <BrowserRouter>
          <Routes>
            <Route path="/segreteria" element={<FirstStep />} />
            <Route path="/" element={<App />} />
            <Route path="/add-child/:location" element={<RegisterNewChild />} />
            {/* <Route path="children" element={<Expenses />} /> */}
          </Routes>
        </BrowserRouter>
      </LocalizationProvider>
    </ThemeProvider >
  </React.StrictMode>,
  document.getElementById('root')
);
