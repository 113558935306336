/* eslint-disable import/no-anonymous-default-export */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import * as React from 'react';
import { Controller, useForm } from "react-hook-form";
import { getErrorMessage } from './util';
import Trips from './Trips'
import Weeks from './Weeks';
import Tshirt from './Tshirt';


export default ({ setForm, formData, navigation, avaibility, location, maxChildren }) => {
    const { handleSubmit, control, watch } = useForm({ defaultValues: formData });
    const { next } = navigation;
    const checkSomeWeekIsSelected = (weeks) => weeks.some(week => week !== undefined);
    const onSubmit = (data) => {
        if (location <= 3 && !checkSomeWeekIsSelected([data.subscription.week_one, data.subscription.week_two, data.subscription.week_three])) {
            alert("Seleziona almeno una settimana");
            return;
        }
        setForm({
            ...formData,
            ...{
                subscription: {
                    ...data.subscription,
                    week_one: data.subscription.week_one || false,
                    week_two: data.subscription.week_two || false,
                    week_three: data.subscription.week_three || false,
                    trip_one: data.subscription.trip_one || false,
                    trip_two: data.subscription.trip_two || false,
                    trip_three: data.subscription.trip_three || false,
                    trip_four: data.subscription.trip_four || false,
                    // trip_five: data.subscription.trip_five || false,
                    pre_ingress: data.subscription.pre_ingress || false,
                    post_exit: data.subscription.post_exit || false,
                    extra_t_shirt: data.subscription.extra_t_shirt || 0,
                    location
                }
            }
        });
        next();
    };
    return (
        <React.Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                    <Weeks control={control} maxChildren={maxChildren} avaibility={avaibility} location={location} />
                    <Trips control={control} watch={watch} location={location} />
                    <Grid item xs={12} sm={12}>
                        {
                            location < 5 && <Controller
                            name="subscription.pre_ingress"
                            control={control}
                            rules={{ required: false }}
                            render={({ field, fieldState }) => <FormControlLabel control={<Switch
                                label={location === "4" ? "Mensa" : "Ingresso anticipato"}
                                helperText={getErrorMessage(fieldState.error)}
                                onChange={(e) => field.onChange(e.target.checked)}
                                checked={field.value || false}
                            />}
                                label={location === "4" ? "Mensa" : "Ingresso anticipato"}
                            />
                            }
                        />
                        }

                        {location < 4 && (<Controller
                            name="subscription.post_exit"
                            control={control}
                            rules={{ required: false }}
                            render={({ field, fieldState }) => <FormControlLabel control={<Switch
                                label="Uscita autonoma"
                                helperText={getErrorMessage(fieldState.error)}
                                onChange={(e) => field.onChange(e.target.checked)}
                                checked={field.value || false}
                            />}
                                label="Uscita autonoma"
                            />
                            }
                        />)}

                    </Grid>
                    <Tshirt control={control} location={location} />
                    {/* <Grid item xs={12} sm={12}>
                        <Controller
                            name="subscription.allergy_and_intolerance"
                            control={control}
                            rules={{ required: false }}
                            render={({ field, fieldState }) => <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                                <TextField
                                    id="subscription.allergy_and_intolerance"
                                    label="Allergie e intolleranze"
                                    multiline
                                    rows={4}
                                    defaultValue=""
                                    variant="standard"
                                    {...field}
                                    {...fieldState}
                                />
                            </FormControl>
                            }
                        />
                    </Grid> */}
                    <Grid item xs={12} sm={12}>
                        <Controller
                            name="subscription.pathologies"
                            control={control}
                            rules={{ required: false }}
                            render={({ field, fieldState }) => <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                                <TextField
                                    id="subscription.pathologies"
                                    label="Patologie"
                                    multiline
                                    rows={4}
                                    defaultValue=""
                                    variant="standard"
                                    {...field}
                                    {...fieldState}
                                />
                            </FormControl>
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Controller
                            name="subscription.payment_type"
                            control={control}
                            rules={{ required: true }}
                            render={({ field, fieldState }) => <FormControl fullWidth error={!!fieldState.error}>
                                <InputLabel id="subscription.payment_type">Tipo di pagamento</InputLabel>
                                <Select
                                    labelId="subscription.payment_type"
                                    id="subscription.payment_type"
                                    label="Tipo di pagamento"
                                    {...field}
                                    {...fieldState}
                                >
                                    <MenuItem value={"Bonifico"}>Bonifico</MenuItem>
                                    <MenuItem value={"Contanti"}>Contanti</MenuItem>
                                </Select>
                                <FormHelperText>{getErrorMessage(fieldState.error)}</FormHelperText>
                            </FormControl>
                            }
                        />
                    </Grid>
                </Grid>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            type='submit'
                            sx={{ mt: 3, ml: 1 }}
                        >
                            Avanti
                        </Button>
                    </Grid>
                </Box>
            </form>
        </React.Fragment>
    );
}
