import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useStep } from "react-hooks-helper";
import { useParams } from 'react-router-dom';
import ChildInfo from './ChildInfo';
import ParentInfo from './ParentInfo';
import Resume from './Resume';
import SubscriptionDetails from './SubscriptionDetails';
import { BASIC_ENDPOINT_BACKEND, MAX_CHILDREN_CALCINATO, MAX_CHILDREN } from "./util";



const steps = [{ id: 'Dettagli iscrizione' }, { id: 'Informazioni sul Bambino/a' }, { id: 'Informazione sui Genitori' }, { id: 'Riepilogo' }];

const getMaxChildren = (location) => {
    switch (parseInt(location)) {
        case 2: {
            return MAX_CHILDREN_CALCINATO
        }
        case 4: {
            return 60
        }
        case 5:
        case 6:
        case 7: {
            return 60
        }
        default: {
            return MAX_CHILDREN
        }
    }
}

export default () => {
    const { location: _location } = useParams();
    const [formData, setForm] = React.useState({});
    const [location] = React.useState(() => {
        switch (parseInt(_location)) {
            case 1:
                return "ponte-san-marco"
            case 2:
                return "calcinato"
            case 3:
                return "calcinatello"
            case 4:
                return "extra-grest"
            case 5:
                return "campo-elementari-1-2"
            case 6:
                return "campo-elementari-3-4-5"
            case 7:
                return "campo-medie"
        }
    });
    const [avaibility, setAvaibility] = React.useState({
        week_one: 0,
        week_two: 0,
        week_three: 0,
    });
    React.useEffect(() => {
        fetch(`${BASIC_ENDPOINT_BACKEND}/available-sit/${_location}`)
            .then(res => res.json())
            .then(setAvaibility)
    }, [location])
    const { step, navigation } = useStep({ initialStep: 0, steps });
    const { id } = step;

    const maxChildren = getMaxChildren(location)
    const props = { formData, setForm, navigation, location: _location };

    function getStepContent(step) {
        switch (step) {
            case "Dettagli iscrizione":
                return <SubscriptionDetails {...props} avaibility={avaibility} maxChildren={maxChildren} />;
            case "Informazioni sul Bambino/a":
                return <ChildInfo {...props} />;
            case "Informazione sui Genitori":
                return <ParentInfo {...props} />;
            case "Riepilogo":
                return <Resume {...props} />;
            default:
                throw new Error('Unknown step');
        }
    }
    return (
        <Container component="main" maxWidth="xl" sx={{ mb: 4 }}>
            <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                <Typography component="h1" variant="h4" align="center">
                    Iscrivi il tuo bambino/a
                </Typography>
                {Object.values(avaibility).every(_ => _ === maxChildren) ? (<h1 style={{ textAlign: "center" }}>Iscrizioni chiuse</h1>) : (
                    <>
                        <Stepper activeStep={steps.findIndex(({ id: _id }) => id === _id)} sx={{ pt: 3, pb: 5 }} orientation="vertical">
                            {steps.map((label) => (
                                <Step key={label.id}>
                                    <StepLabel>{label.id}</StepLabel>
                                    <StepContent>{getStepContent(id)}</StepContent>
                                </Step>
                            ))}
                        </Stepper>
                    </>
                )}

            </Paper>
        </Container>
    );
}
