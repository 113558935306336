/* eslint-disable import/no-anonymous-default-export */
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import * as React from 'react';
import { Controller } from "react-hook-form";
import { getErrorMessage } from './util';

export default ({ control, watch, location }) => {
    if (location > 4) {
        return null
    }
    console.log(location)
    return (
        <Grid item xs={12} sm={6}>
            <Controller
                name="subscription.trip_one"
                control={control}

                render={({ field, fieldState }) => <FormControlLabel control={<Checkbox
                    helperText={getErrorMessage(fieldState.error)}
                    disabled={location < 5 ? !watch('subscription.week_one') : false}
                    onChange={(e) => field.onChange(e.target.checked)}
                    checked={field.value || false} />}
                    label="Prima gita"

                />

                }
            />
            <Controller
                name="subscription.trip_two"
                control={control}
                render={({ field, fieldState }) => <FormControlLabel control={<Checkbox
                    helperText={getErrorMessage(fieldState.error)}
                    onChange={(e) => field.onChange(e.target.checked)}
                    disabled={location < 5 ? (location == 4 ? !watch('subscription.week_two') : !watch('subscription.week_two')) : false}
                    checked={field.value || false} />}
                    label="Seconda gita"
                />

                }
            />
            <Controller
                name="subscription.trip_three"
                control={control}
                render={({ field, fieldState }) => <FormControlLabel control={<Checkbox
                    helperText={getErrorMessage(fieldState.error)}
                    onChange={(e) => field.onChange(e.target.checked)}
                    disabled={location < 5 ? (location == 4 ? !watch('subscription.week_three') : !watch('subscription.week_two')) : false}
                    checked={field.value || false} />}
                    label="Terza gita"

                />

                }
            />
            {location < 4 && (<>
                <Controller
                name="subscription.trip_four"
                control={control}
                render={({ field, fieldState }) => <FormControlLabel control={<Checkbox
                    helperText={getErrorMessage(fieldState.error)}
                    onChange={(e) => field.onChange(e.target.checked)}
                    disabled={location < 4 ? !watch('subscription.week_three') : false}
                    checked={field.value || false} />}
                    label="Quarta gita"

                />

                }
            />
                {/* <Controller
                name="subscription.trip_five"
                control={control}
                render={({ field, fieldState }) => <FormControlLabel control={<Checkbox
                    helperText={getErrorMessage(fieldState.error)}
                    onChange={(e) => field.onChange(e.target.checked)}
                    disabled={location < 4 ? !watch('subscription.week_three') : false}
                    checked={field.value || false} />}
                    label="Quinta gita"

                />

                }
                /> */}
            </>)
            }
        </Grid>
    )
}