import * as React from 'react';
import { useForm, Controller } from "react-hook-form";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { getErrorMessage } from './util';


export default ({ setForm, formData, navigation }) => {
    const { handleSubmit, control, formState } = useForm({ defaultValues: formData });

    const { next, previous } = navigation;
    const onSubmit = (data) => {
        setForm({ ...formData, ...data });
        next();
    };
    const goBack = () => {
        setForm({ ...formData, ...formState });
        previous();
    };
    return (
        <React.Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="father_name"
                            control={control}
                            rules={{ required: true }}
                            render={({ field, fieldState }) => <TextField
                                id="father_name"
                                name="father_name"
                                label="Nome del padre *"
                                fullWidth
                                variant="standard"
                                helperText={getErrorMessage(fieldState.error)}
                                {...field}
                                {...fieldState}
                            />
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="father_surname"
                            control={control}
                            rules={{ required: true }}
                            render={({ field, fieldState }) => <TextField
                                id="father_surname"
                                name="father_surname"
                                label="Cognome del padre *"
                                fullWidth
                                variant="standard"
                                helperText={getErrorMessage(fieldState.error)}
                                {...field}
                                {...fieldState}
                            />
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="mother_name"
                            control={control}
                            rules={{ required: true }}
                            render={({ field, fieldState }) => <TextField
                                id="mother_name"
                                name="mother_name"
                                label="Nome della madre *"
                                fullWidth
                                variant="standard"
                                helperText={getErrorMessage(fieldState.error)}
                                {...field}
                                {...fieldState}
                            />
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="mother_surname"
                            control={control}
                            rules={{ required: true }}
                            render={({ field, fieldState }) => <TextField
                                id="mother_surname"
                                name="mother_surname"
                                label="Cognome della madre *"
                                fullWidth
                                variant="standard"
                                helperText={getErrorMessage(fieldState.error)}
                                {...field}
                                {...fieldState}
                            />
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            name="phone"
                            control={control}
                            rules={{ required: true }}
                            render={({ field, fieldState }) => <TextField
                                id="phone"
                                name="phone"
                                label="Cellulare di un genitore *"
                                fullWidth
                                variant="standard"
                                helperText={getErrorMessage(fieldState.error)}
                                {...field}
                                {...fieldState}
                            />
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            name="email"
                            control={control}
                            rules={{ required: true }}
                            render={({ field, fieldState }) => <TextField
                                id="email"
                                name="email"
                                label="Email *"
                                fullWidth
                                variant="standard"
                                helperText={getErrorMessage(fieldState.error)}
                                {...field}
                                {...fieldState}
                            />
                            }
                        />
                    </Grid>

                </Grid>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            type='button'
                            onClick={goBack}
                            sx={{ mt: 3, ml: 1 }}
                        >
                            Indietro
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            type='submit'
                            sx={{ mt: 3, ml: 1 }}
                        >
                            Avanti
                        </Button>
                    </Grid>
                </Box>
            </form>
        </React.Fragment>
    );
}