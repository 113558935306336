/* eslint-disable import/no-anonymous-default-export */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import * as React from 'react';
import { Controller, useForm } from "react-hook-form";
import { useParams } from 'react-router-dom';
import { getErrorMessage } from './util';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';


export default ({ setForm, formData, navigation, location }) => {
    const { handleSubmit, control, formState } = useForm({ defaultValues: formData });

    const { next, previous } = navigation;
    const goBack = () => {
        setForm({ ...formData, ...formState });
        previous();
    };
    const onSubmit = (data) => {
        setForm({ ...formData, ...data });
        next();
    };
    const [min, max] = React.useMemo(() => {
        switch (parseInt(location)) {
            case 1:
            case 4:
                return [new Date(2015, 11, 31), new Date(2017, 12, 31)]
            case 5:
            case 2:
                return [new Date(2012, 11, 31), new Date(2015, 12, 31)]
            case 3:
            case 6:
                return [new Date(2009, 11, 31), new Date(2012, 12, 31)]
            case 7:
                return [new Date(2005, 11, 31), new Date(2010, 12, 31)]
        };
    }, [])
    return (
        <React.Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>

                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="name"
                            control={control}
                            rules={{ required: true }}
                            render={({ field, fieldState }) => <TextField
                                id="name"
                                name="name"
                                label="Nome *"
                                fullWidth
                                variant="standard"
                                helperText={getErrorMessage(fieldState.error)}
                                {...field}
                                {...fieldState}
                            />
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="surname"
                            control={control}
                            rules={{ required: true }}
                            render={({ field, fieldState }) => <TextField
                                id="surname"
                                name="surname"
                                label="Cognome *"
                                fullWidth
                                variant="standard"
                                helperText={getErrorMessage(fieldState.error)}
                                {...field}
                                {...fieldState}
                            />
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="birth"
                            control={control}
                            defaultValue={min}
                            rules={{ required: true }}
                            render={({ field, fieldState }) =>
                                <DatePicker
                                    id="birth"
                                    mask="__/__/____"
                                    name="birth"
                                    label="Data di nascita *"
                                    helperText={getErrorMessage(fieldState.error)}
                                    {...field}
                                    {...fieldState}
                                    maxDate={max}
                                    minDate={min}
                                    renderInput={(params) => <TextField {...params} variant="standard" fullWidth />}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="birth_location"
                            control={control}
                            rules={{ required: true }}
                            render={({ field, fieldState }) => <TextField
                                id="birth_location"
                                name="birth_location"
                                label="Luogo di nascita *"
                                fullWidth
                                variant="standard"
                                helperText={getErrorMessage(fieldState.error)}
                                {...field}
                                {...fieldState}
                            />
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            name="city"
                            control={control}
                            rules={{ required: true }}
                            render={({ field, fieldState }) => <TextField
                                id="address"
                                name="address"
                                label="Residente a"
                                fullWidth
                                variant="standard"
                                helperText={getErrorMessage(fieldState.error)}
                                {...field}
                                {...fieldState}
                            />
                            }
                        />
                        <Controller
                            name="address"
                            control={control}
                            rules={{ required: true }}
                            render={({ field, fieldState }) => <TextField
                                id="address"
                                name="address"
                                label="Indirizzo"
                                fullWidth
                                variant="standard"
                                helperText={getErrorMessage(fieldState.error)}
                                {...field}
                                {...fieldState}
                            />
                            }
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="subscription.sex"
                            control={control}
                            rules={{ required: true }}
                            render={({ field, fieldState }) => <FormControl fullWidth error={!!fieldState.error}>
                                <InputLabel id="subscription.sex">Sesso</InputLabel>
                                <Select
                                    labelId="subscription.sex"
                                    id="subscription.sex"
                                    label="Sesso"
                                    {...field}
                                    {...fieldState}
                                >
                                    <MenuItem value={"Maschio"}>Maschio</MenuItem>
                                    <MenuItem value={"Femmina"}>Femmina</MenuItem>
                                </Select>
                                <FormHelperText>{getErrorMessage(fieldState.error)}</FormHelperText>
                            </FormControl>
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="subscription.class"
                            control={control}
                            rules={{ required: true }}
                            render={({ field, fieldState }) => <TextField
                                id="class"
                                name="class"
                                label="Classe frequentata"
                                fullWidth
                                variant="standard"
                                {...field}
                                {...fieldState}
                            />
                            }
                        />
                    </Grid>
                </Grid>

                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            type='button'
                            onClick={goBack}
                            sx={{ mt: 3, ml: 1 }}
                        >
                            Indietro
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            type='submit'
                            sx={{ mt: 3, ml: 1 }}
                        >
                            Avanti
                        </Button>
                    </Grid>
                </Box>
            </form>
        </React.Fragment>
    );
}
