/* eslint-disable import/no-anonymous-default-export */
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { lightFormat } from 'date-fns';
import React from 'react';
import { useNavigate } from 'react-router-dom'
import { BASIC_ENDPOINT_BACKEND } from "./util";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default ({ formData, navigation, location }) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const navigate = useNavigate()
    const [statusApi, setStatusApi] = React.useState("idle");
    const handleClose = () => {
        setOpen(false);
        if (statusApi === "success") {
            navigate("/")
        }
        setTimeout(() => {
            setStatusApi("idle")
        }, 1000);
    };
    const { previous } = navigation;
    const goBack = () => {
        previous();
    };
    const ModalConfirm = () => (
        <>
            <Typography id="transition-modal-title" variant="h6" component="h2">
                Confermare Iscrizione?
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                Cliccando su SI verrà inoltrata l'iscrizione e verrà scaricato un modulo da firmare e consegnare in oratorio.<br />
                All'interno del modulo sono anche descritte le modalità di pagamento.
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="error"
                        type='button'
                        onClick={goBack}
                        sx={{ mt: 3, ml: 1 }}
                    >
                        No
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="success"
                        type='button'
                        onClick={() => setStatusApi("start")}
                        sx={{ mt: 3, ml: 1 }}
                    >
                        Si
                    </Button>
                </Grid>
            </Box>
        </>
    );
    const getStatus = () => {
        switch (statusApi) {
            case "pending":
                return (
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress />
                    </Box>
                );
            case "success":
                return (<Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <h1>Iscrizione avvenuta</h1>
                </Box>)
            case "error":
                return (<Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <h1>Errore. Riprova più tardi</h1>
                </Box>)
            default:
                return <ModalConfirm />;
        }
    }
    React.useEffect(() => {
        if (statusApi === "start") {
            setStatusApi("pending");
            axios.post(`${BASIC_ENDPOINT_BACKEND}/new-children`, { ...formData, birth: lightFormat(formData.birth, "dd/MM/yyyy"), }, {
                responseType: 'arraybuffer',
            })
                .then(response => {
                    const type = response.headers['content-type']
                    const url = window.URL.createObjectURL(new Blob([response.data], { encoding: 'UTF-8', type }))
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'iscrizione-grest-2024.docx'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                    setStatusApi("success");
                })
        }
    }, [statusApi]);
    return (
        <>
            <Modal
                aria-labelledby="Conferma iscrizione"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        {getStatus()}
                    </Box>
                </Fade>
            </Modal>

            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <Typography variant="h6" gutterBottom>
                        Informazioni sul Bambino/a
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={6} marginLeft={2}>
                    <Grid container spacing={3}>
                        <Grid item >
                            <Typography variant="p">
                                <b>Nome e Cognome:</b> {formData.name} {formData.surname}
                            </Typography>
                        </Grid>
                        <Grid item >
                            <Typography variant="p">
                                <b>Nato a</b> {formData.birth_location} <b>il</b> {lightFormat(formData.birth, "dd/MM/yyyy")}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6} sm={6} marginLeft={2}>
                    <Grid container spacing={3}>
                        <Grid item >
                            <Typography variant="p">
                                <b>Residente a </b> {formData.city}
                            </Typography>
                        </Grid>
                        <Grid item >
                            <Typography variant="p">
                                <b>Indirizzo </b> {formData.address}
                            </Typography>
                        </Grid>

                    </Grid>
                </Grid >
            </Grid>
            <Divider variant="fullWidth" style={{ marginTop: "10px", marginBottom: "10px" }} />
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <Typography variant="h6" gutterBottom>
                        Informazioni sui Genitori
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={6} marginLeft={2}>
                    <Grid container spacing={3}>
                        <Grid item >
                            <Typography variant="p">
                                <b>Padre:</b> {formData.father_name} {formData.father_surname}
                            </Typography>
                        </Grid>
                        <Grid item >
                            <Typography variant="p">
                                <b>Madre</b> {formData.mother_name} {formData.mother_surname}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6} sm={6} marginLeft={2}>
                    <Grid container spacing={3}>
                        <Grid item >
                            <Typography variant="p">
                                <b>Cellulare di un genitore: </b> {formData.phone}
                            </Typography>
                        </Grid>
                        <Grid item >
                            <Typography variant="p">
                                <b>Email: </b> {formData.email}
                            </Typography>
                        </Grid>

                    </Grid>
                </Grid >
            </Grid >
            <Divider variant="fullWidth" style={{ marginTop: "10px", marginBottom: "10px" }} />
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <Typography variant="h6" gutterBottom>
                        Dettagli iscrizione
                    </Typography>
                </Grid>
                <Grid item xs={6} sm={6} marginLeft={2}>
                    <Grid container spacing={3}>
                        {location <= 3 && (<Grid item >
                            <Typography variant="p">
                                <b>Settimane:</b> {formData.subscription.week_one && "1°"} {formData.subscription.week_two && "2°"} {formData.subscription.week_three && "3°"}
                            </Typography>
                        </Grid>)}
                        {
                            location <= 4 && (<Grid item >
                            <Typography variant="p">
                                    <b>Gite:</b> {formData.subscription.trip_one && "1°"} {formData.subscription.trip_two && "2°"} {formData.subscription.trip_three && "3°"} {formData.subscription.trip_four && "4°"} {formData.subscription.trip_five && "5°"}
                            </Typography>
                            </Grid>)
                        }
                    </Grid>
                </Grid>
                <Grid item xs={6} sm={6} marginLeft={2}>
                    <Grid container spacing={3}>
                        {location < 5 && (<Grid item>
                            <Typography variant="p">
                                <b>{location == 4 ? "Mensa" : "Ingresso anticipato"}:</b> {formData.subscription.pre_ingress ? "Si" : "No"}
                            </Typography>
                        </Grid>)}
                        {location < 4 && (<Grid item>
                            <Typography variant="p">
                                <b>Uscita autonoma:</b> {formData.subscription.post_exit ? "Si" : "No"}
                            </Typography>
                        </Grid>)}
                    </Grid>
                </Grid>
                {location <= 4 && (<Grid item xs={6} sm={6} marginLeft={2}>
                    <Grid container spacing={3}>
                        <Grid item >
                            <Typography variant="p">
                                <b>Taglia maglietta: </b> {formData.subscription.size}
                            </Typography>
                        </Grid>
                        <Grid item >
                            <Typography variant="p">
                                <b>Magliette extra: </b> {formData.subscription.extra_t_shirt || 0}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>)}
                <Grid item xs={6} sm={6} marginLeft={2}>
                    <Grid container spacing={3}>
                        {/* <Grid item >
                            <Typography variant="p">
                                <b>Allergie e intolleranze: </b>
                                <p>
                                    {formData.subscription.allergy_and_intolerance}
                                </p>
                            </Typography>
                        </Grid> */}
                        <Grid item >
                            <Typography variant="p">
                                <b>Patologie: </b>
                                <p>
                                    {formData.subscription.pathologies}
                                </p>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        type='button'
                        onClick={goBack}
                        sx={{ mt: 3, ml: 1 }}
                    >
                        Indietro
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="success"
                        type='button'
                        onClick={handleOpen}
                        sx={{ mt: 3, ml: 1 }}
                    >
                        Conferma
                    </Button>
                </Grid>
            </Box>
        </>
    )
}
