import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import * as React from 'react';
import { Controller } from "react-hook-form";
import { getErrorMessage } from './util';

export default ({ avaibility, control, maxChildren, location }) => {
    if (location > 4) {
        return null
    }
    return (
        <Grid item xs={12} sm={6}>
            <Controller
                name="subscription.week_one"
                control={control}
                render={({ field, fieldState }) => <FormControlLabel disabled={avaibility.week_one === maxChildren} control={<Checkbox helperText={getErrorMessage(fieldState.error)}
                    onChange={(e) => field.onChange(e.target.checked)}
                    checked={field.value || false}
                />}
                    label="Prima settimana"
                />
                }
            />
            <Controller
                name="subscription.week_two"
                control={control}
                render={({ field, fieldState }) => <FormControlLabel disabled={avaibility.week_two === maxChildren} control={<Checkbox helperText={getErrorMessage(fieldState.error)}
                    onChange={(e) => field.onChange(e.target.checked)}
                    checked={field.value || false} />}
                    label="Seconda settimana"

                />

                }
            />
            <Controller
                name="subscription.week_three"
                control={control}
                render={({ field, fieldState }) => <FormControlLabel disabled={avaibility.week_three === maxChildren} control={<Checkbox
                    helperText={getErrorMessage(fieldState.error)}
                    onChange={(e) => field.onChange(e.target.checked)}
                    checked={field.value || false} />}
                    label="Terza settimana"

                />

                }
            />
        </Grid>
    )
}