import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import logoUp from './logoUp';


export default ({ done }) => {
  return (
    <Container component="main" maxWidth="xl">
      <Paper className="intro" variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 3, md: 3 } }} style={{ flexDirection: 'column' }}>
        <img style={{ margin: "auto", marginBottom: "10px", maxWidth: "260px" }} src={logoUp} />
        <Typography component="h4" variant="h4" align="center">
          Benvenuto sul portale per le iscrizioni alle attivitá estive 2024
        </Typography>
        <p>
          Il portale delle iscrizioni é chiuso.
          <br />
          Sará possibile iscriversi solamente presso la segreteria dell'oratorio giovedí 18 e sabato 20 nei seguenti orari:
          <ul>
            <li>Giovedì 20:00-21:30</li>
            <li>Sabato 19:00-21:00</li>
          </ul>
        </p>
      </Paper>

    </Container>
  );
}

