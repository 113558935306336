export const getErrorMessage = (error) => {
    if (!error) return "";

    switch (error.type) {
        case "required":
            return "Questo campo è obbligatorio";
        default:
            return "";
    }
}
export const BASIC_ENDPOINT_BACKEND = "https://upbackend.fly.dev";

export const MAX_CHILDREN = 120
export const MAX_CHILDREN_CALCINATO = 120
