import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import * as React from 'react';
import { Controller } from "react-hook-form";
import { getErrorMessage } from './util';

export default ({ control, location }) => {
    if (location > 4) {
        return null
    }
    return (
        <>
            <Grid item xs={12} sm={6}>
                <Controller
                    name="subscription.size"
                    control={control}
                    rules={{ required: true }}
                    render={({ field, fieldState }) => <FormControl fullWidth error={!!fieldState.error}>
                        <InputLabel id="subscription.size">Taglia maglietta</InputLabel>
                        <Select
                            labelId="subscription.size"
                            id="subscription.size"
                            label="Taglia maglietta"
                            {...field}
                            {...fieldState}
                        >
                            <MenuItem value={"5/6"}>5/6</MenuItem>
                            <MenuItem value={"7/8"}>7/8</MenuItem>
                            <MenuItem value={"9/11"}>9/11</MenuItem>
                            <MenuItem value={"12/14"}>12/14</MenuItem>
                            <MenuItem value={"S"}>S</MenuItem>
                            <MenuItem value={"M"}>M</MenuItem>
                            <MenuItem value={"L"}>L</MenuItem>
                            <MenuItem value={"XL"}>XL</MenuItem>
                            <MenuItem value={"XXL"}>XXL</MenuItem>
                        </Select>
                        <FormHelperText>{getErrorMessage(fieldState.error)}</FormHelperText>
                    </FormControl>
                    }
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Controller
                    name="subscription.extra_t_shirt"
                    control={control}
                    render={({ field, fieldState }) => <TextField
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', min: 0 }}
                        name="subscription.extra_t_shirt"
                        fullWidth
                        type={'number'}
                        label="Magliette extra"
                        helperText={getErrorMessage(fieldState.error)}
                        {...field}
                        {...fieldState}
                    />
                    }
                />
            </Grid>
        </>
    )
}